import request from '@/auth/jwt/useJwt'

// 获取用户数据
export function query(pageIndex, pageSize, params) {
  let requestUrl = `/tenant/detail/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&sorts[0].name=id&sorts[0].order=desc`
  let index = 0
  if (params) {
    if (params.name) {
      requestUrl += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
      index += 1
    }
    if (params.state) {
      requestUrl += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
    }
  }
  return request.axiosIns({
    url: encodeURI(requestUrl),
    method: 'get',
  })
}
/*  新增租户  */
export function add(data) {
  return request.axiosIns({
    url: 'tenant/_create',
    method: 'post',
    data,
  })
}
/* 编辑租户 */
export function edit(id, data) {
  return request.axiosIns({
    url: `tenant/${id}`,
    method: 'PUT',
    data,
  })
}
/* 删除证书 */
export function delById(id) {
  return request.axiosIns({
    url: `user/${id}`,
    method: 'DELETE',
  })
}

// 改变状态
export function editStatus(data) {
  return request.axiosIns({
    url: `tenant/${data.id}`,
    method: 'PUT',
    data,
  })
}
// 根据租户id查询成员数组
export function findTenantMembers(id) {
  return request.axiosIns({
    url: `tenant/${id}/members/_query/no-paging?paging=false`,
    method: 'get',
  })
}
// 查询成员产品各状态数量
export function productCount(params, state) {
  return request.axiosIns({
    url: encodeURI(`device-product/_count?terms[0].column=id$assets&terms[0].value=${params}&terms[1].column=state&terms[1].value=${state}`),
    method: 'get',
  })
}

// 查询设备各状态数量
export function deviceCount(params, state) {
  return request.axiosIns({
    url: encodeURI(`device/instance/_count?terms[0].column=id$assets&terms[0].value=${params}&terms[1].column=state&terms[1].value=${state}`),
    method: 'get',
  })
}
// 获取租户产品列表
export function queryTenantProducts(tenant, pageIndex, pageSize, params) {
  let requestUrl = `/device-product/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=id$assets&terms[0].value=${JSON.stringify(tenant)}`
  let index = 1
  if (params) {
    if (params.id) {
      requestUrl += `&terms[${index}].column=id$LIKE&terms[${index}].value=%${params.id}%`
      index++
    }
    if (params.name) {
      requestUrl += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
      index++
    }
    if (params.state !== undefined && params.state !== null) {
      requestUrl += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
    }
  }
  return request.axiosIns({
    url: encodeURI(requestUrl),
    method: 'get',
  })
}
// 获取租户设备列表
export function queryTenantDevices(tenant, pageIndex, pageSize, params) {
  let requestUrl = `/device/instance/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=id$assets&terms[0].value=${JSON.stringify(tenant)}`
  let index = 1
  if (params) {
    if (params.id) {
      requestUrl += `&terms[${index}].column=id$LIKE&terms[${index}].value=%${params.id}%`
      index++
    }
    if (params.name) {
      requestUrl += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
      index++
    }
    if (params.productId) {
      requestUrl += `&terms[${index}].column=productId&terms[${index}].value=${params.productId}`
      index++
    }
    if (params.state !== undefined && params.state !== null) {
      requestUrl += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
    }
  }
  return request.axiosIns({
    url: encodeURI(requestUrl),
    method: 'get',
  })
}
// 获取租户未绑定的设备列表
export function getDeviceListTenantUnbind(pageIndex, params) {
  return request.axiosIns({
    url: encodeURI(`device/instance/_query?terms[0].column=id$assets&terms[0].value=${params}&pageIndex=${pageIndex}&pageSize=10`),
    method: 'get',
  })
}
// 解除绑定
export function unbinds(tenantId, params) {
  return request.axiosIns({
    url: `tenant/${tenantId}/assets/_unbind`,
    method: 'POST',
    data: params,
  })
}
// 绑定产品
export function binds(tenantId, params) {
  return request.axiosIns({
    url: `tenant/${tenantId}/assets/_bind`,
    method: 'POST',
    data: params,
  })
}

// 查询租户成员列表
export function queryMemberList(pageIndex, pageSize, params, tenantId) {
  let requestUrl = `tenant/${tenantId}/members/_query?pageIndex=${pageIndex}&pageSize=${pageSize}`
  let index = 0
  if (params) {
    if (params.name) {
      requestUrl += `&terms[${index}].column=name&terms[${index}].value=${params.name}`
      index += 1
    }
    if (params.state) {
      requestUrl += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
// 解绑成员
export function unbindMember(tenantId, data) {
  return request.axiosIns({
    url: `tenant/${tenantId}/members/_unbind`,
    method: 'POST',
    data,
  })
}
// 查询未绑定成员 选择绑定
export function queryUnbindMembers(tenantId, pageIndex, pageSize, params) {
  let requestUrl = `/user/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=id$tenant-user$not&terms[0].value=${tenantId}`
  requestUrl += '&terms[1].column=status&terms[1].value=1'
  if (params) {
    let index = 2
    if (params.name) {
      requestUrl += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
      index++
    }
    if (params.username) {
      requestUrl += `&terms[${index}].column=username$LIKE&terms[${index}].value=%${params.username}%`
    }
  }
  return request.axiosIns({
    url: encodeURI(requestUrl),
    method: 'get',
  })
}

// 绑定成员
export function bindMember(tenantId, params) {
  return request.axiosIns({
    url: `tenant/${tenantId}/members/_bind`,
    method: 'post',
    data: params,
  })
}
