<template>
  <div>
    <x-table
      title="租户列表"
      ref="table"
      :customXForm="true"
      :card="true"
      :options="options"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete"
      @rowDisabled="editStatus"
      @rowView="rowView"
      @rowEnabled="editStatus">
      <template slot="createTime" slot-scope="scope">
        <div>{{ scope.row.tenant.createTime ? new Date(scope.row.tenant.createTime).cxFormat('yyyy-MM-dd hh:mm'): '/'  }} </div>
      </template>
      <template slot="photo" slot-scope="scope">
        <div v-if="scope.row.tenant.photo">
          <b-avatar
            v-viewer
            size="lg"
            :src="scope.row.tenant.photo"
            text="avatar"
            variant="light-primary"
          />
        </div>
        <div v-else>
          <b-avatar
            size="lg"
            style="font-size: 26px"
            variant="light-secondary">
          </b-avatar>
        </div>
      </template>
      <template slot="name" slot-scope="scope">
        <div>
          {{scope.row.tenant.name}}
        </div>
      </template>
      <template slot="state" slot-scope="scope">
        <div class="d-flex align-items-center">
          <div class="x-dot" :class="scope.row.tenant.state.value === 'enabled' ? 'bg-success' : 'bg-danger'"></div>
          {{scope.row.tenant.state.text}}
        </div>
      </template>
      <template slot="members" slot-scope="scope">
        <div>
          <b-badge variant="light-success">
            {{scope.row.members}}
          </b-badge>
        </div>
      </template>
    </x-table>
    <b-sidebar
      id="sidebar-right"
      ref="slider"
      idebar-class="sidebar-xl"
      bg-variant="white"
      right
      backdrop
      shadow
      title="新增租户"
      :visible="visible"
      width="820px"
      @hidden="hidden"
    >
      <tenant-form
        v-if="visible"
        @editSuccess="editSuccess"
      />
    </b-sidebar>
    <b-sidebar
      id="sidebar-detail"
      ref="sliderDetail"
      idebar-class="sidebar-xl"
      bg-variant="white"
      right
      backdrop
      shadow
      title="租户详情"
      :visible="detailVisible"
      width="75%"
      @hidden="detailHidden"
    >
      <tenant-detail
        v-if="detailVisible"
        :detail="detail"
      />
    </b-sidebar>
    <b-modal
      title-class="x-text-bold"
      body-class="pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title="确定"
      cancel-title="取消"
      centered
      title="预览"
      :visible.sync="dialogVisible"
      @hidden="restModal">
      <img width="100%" :src="dialogImageUrl" alt="">
    </b-modal>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  query, add, delById, editStatus, edit,
} from '@/api/system/setup/tenant'
import tenantForm from '@/views/system/setup/tenant/tenantForm.vue'
import tenantDetail from '@/views/system/setup/tenant/tenantDetail.vue'
import {
  BAvatar, BBadge, BSidebar, BModal,
} from 'bootstrap-vue'

export default {
  components: {
    XTable,
    BAvatar,
    BBadge,
    BSidebar,
    tenantForm,
    tenantDetail,
    BModal,
  },
  data() {
    return {
      // 租户详情页面传递对象
      detail: {},
      dialogImageUrl: '',
      dialogVisible: false,
      visible: false,
      detailVisible: false,
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        searchFold: true,
        lableVertical: true,
        // 新增按钮
        addBtn: true,
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        actions: [
          { action: 'view', name: '查看', tip: '查看', icon: 'EyeIcon', customXForm: true },
          { action: 'disabled', name: '禁用', tip: '禁用', icon: 'SlashIcon' },
          { action: 'enabled', name: '启用', tip: '启用', icon: 'RepeatIcon' },
        ],
        actionFilter: (action, row) => {
          if (row.tenant.state.value === 'enabled') {
            return action === 'view' || action === 'disabled'
          }
          return action === 'view' || action === 'enabled'
        },
        columns: [{
          label: '头像',
          labelShow: true,
          prop: 'photo',
          searchShow: false,
        }, {
          label: '名称',
          labelShow: true,
          prop: 'name',
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入姓名',
          },
        }, {
          label: '密码',
          labelShow: true,
          prop: 'password',
          searchShow: false,
          rowShow: false,
          viewShow: false,
          rules: {
            rule: 'required',
            message: '请输入密码',
          },
        },
        {
          label: '成员数',
          labelShow: true,
          prop: 'members',
          rowShow: true,
          searchShow: false,
        },
        {
          label: '创建时间',
          labelShow: true,
          prop: 'createTime',
          rowShow: true,
          searchShow: false,
        },
        {
          label: '状态',
          labelShow: true,
          type: 'select',
          prop: 'state',
          dictData: [{ id: 'disabled', name: '已禁用' }, { id: 'enabled', name: '已启用' }],
          props: { label: 'name', value: 'id' },
          rowShow: true,
          searchShow: true,
        },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowEdit(data, done) {
      edit(data).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      delById(data.id).then(() => {
        done()
      })
    },
    addHandle() {
      this.visible = true
    },
    rowDisable(data, done) {
      let form = {
        id: data.id,
        status: 0,
      }
      add(form).then(() => {
        done()
      })
    },
    rowEnable(data, done) {
      let form = {
        id: data.id,
        status: 1,
      }
      add(form).then(() => {
        done()
      })
    },
    hidden() {
      this.visible = false
    },
    detailHidden() {
      this.detailVisible = false
      this.$refs.table.__searchHandle()
    },
    editSuccess() {
      this.hidden()
      this.$refs.table.__searchHandle()
    },
    // 启用/禁用
    editStatus(data, done) {
      let formData = data.tenant
      formData.members = data.members
      if (formData.state.value === 'enabled') {
        formData.state = 'disabled'
      }
      if (formData.state.value === 'disabled') {
        formData.state = 'enabled'
      }
      editStatus(formData).then(() => {
        done()
      })
    },
    handlePictureCardPreview(photo) {
      if (photo) {
        this.dialogImageUrl = photo
        // this.dialogImageUrl = file.url
        this.dialogVisible = true
      }
    },
    restModal() {
      this.dialogVisible = false
    },
    rowView(row) {
      this.detailVisible = true
      this.detail = this._.cloneDeep(row)
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
</style>
