<template>
  <div>
    <b-tabs
     v-model="tabIndex"
    class="pl-3 pr-3 pb-2">
    <b-tab
      active
      title="基本信息">
      <x-form-validator ref="refFormObserver">
        <x-form-feild label="头像">
          <div style="position: relative;width: 100px;height: 100px"  @click="handlePictureCardPreview">
            <b-avatar
              :class="formData.photo ? 'el-upload-list__item-thumbnail upload-cx' : 'el-upload-list__item-thumbnail'"
              style="width: 100px;height: 100px;"
              size="lg"
              :src="formData.photo" alt=""
              :variant="formData.photo ? 'light-primary' : 'light-secondary'"
            >
            </b-avatar>
          </div>
          <el-upload
            :action="action"
            :headers="headers"
            :multiple="false"
            :file-list="fileList"
            accept=".jpg, .png, .gif"
            :show-file-list="false"
            :on-exceed="onExceed"
            :on-success="handleSuccess">
            <b-button  variant="primary" size="sm" v-ripple.400="$x.ripple.primary" class="mt-1 ml-2" style="margin-left: 10px">上传</b-button>
          </el-upload>
          <b-modal
            title-class="x-text-bold"
            body-class="pl-4 pr-4"
            cancel-variant="flat-secondary"
            size="lg"
            ok-title="删除"
            cancel-title="取消"
            centered
            title="预览"
            :visible.sync="dialogVisible"
            @hidden="restModal"
            @ok="handleRemove">
            <img width="100%" :src="dialogImageUrl" alt="">
          </b-modal>
        </x-form-feild>
        <x-form-feild label="名称">
          <b-form-input
            v-model="formData.name"
            placeholder="请输入名称"
          />
        </x-form-feild>
        <x-form-feild label="说明">
          <b-form-textarea
            v-model="formData.description"
            placeholder="请输入描述"
          />
        </x-form-feild>
        <x-form-feild label="创建日期">
          {{ new Date(formData.createTime).cxFormat('yyyy-MM-dd hh:mm')  }}
        </x-form-feild>
        <x-form-feild label="使用状态">
          <b-badge variant="light-success" v-if="formData.state.value === 'enabled'">
            {{formData.state.text}}
          </b-badge>
          <b-badge variant="light-warning" v-else>
            {{formData.state.text}}
          </b-badge>
        </x-form-feild>
        <b-button class="ml-1 full-width" variant="primary" v-ripple.400="$x.ripple.primary" @click="enter">更新信息</b-button>
      </x-form-validator>
    </b-tab>
    <b-tab
      title="资产信息">
      <assets-info
        v-if="formData.id"
        :id="formData.id"
        :detail="detail"
        :memberId="memberId"
      />
    </b-tab>
    <b-tab
      title="成员管理"
      lazy
    >
      <member-list
        v-if="formData.id"
        :id="formData.id"
        :detail="detail"
        @toggleMember="toggleMember"
      />
    </b-tab>
    <b-tab
      title="查看权限">
      <authz-form
        v-if="formData.id"
        :targetId="detail.tenant.id"
        :targetType="targetType"
      />
    </b-tab>
  </b-tabs>
  </div>
</template>
<script>
import { BFormInput, BFormTextarea, BButton, BModal, BAvatar, BTabs, BTab, BBadge } from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import AuthzForm from '@/views/system/setup/utils/AuthzForm.vue'
import assetsInfo from '@/views/system/setup/tenant/assetsInfo.vue'
import memberList from '@/views/system/setup/tenant/memberList.vue'
import { edit } from '@/api/system/setup/tenant'
import { getToken } from '@/@core/auth/token'

export default {
  components: {
    XFormValidator,
    XFormFeild,
    BFormInput,
    BFormTextarea,
    BButton,
    BModal,
    BAvatar,
    BTabs,
    BTab,
    BBadge,
    assetsInfo,
    memberList,
    AuthzForm,
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 赋权参数
      targetType: 'tenant',
      tabIndex: 0,
      memberId: undefined,
      formData: {
        photo: undefined,
        name: undefined,
        password: undefined,
        confirmPassword: false,
        username: undefined,
        description: undefined,
      },
      errorFlag: false,
      confirmFieldType: 'password',
      oldPasswordFieldType: 'password',
      newPasswordFieldType: 'password',
      // 上传请求地址
      action: this.$xapi.UPLOAD,
      // 上传请求头部信息
      headers: {
        'X-Access-Token': getToken(),
      },
      dialogVisible: false,
      dialogImageUrl: '',
      fileList: [],
    }
  },
  watch: {
    tabIndex(val) {
      console.log(val)
    },
    detail: {
      immediate: true,
      handler(val) {
        if (val) {
          this.formData = this._.cloneDeep(val.tenant)
        }
      },
    },
  },
  methods: {
    // 上传图片
    uploadSuccess(response) {
      this.$xtoast.success('上传成功')
      if (response.code === 'success') {
        this.formData.photo = response.result
      }
    },
    enter() {
      edit(this.formData.id, this.formData).then(() => {
        this.$xtoast.success('更新成功！')
      })
    },
    handleRemove() {
      this.fileList = []
      this.formData.photo = ''
    },
    handlePictureCardPreview() {
      if (this.formData.photo) {
        this.dialogImageUrl = this.formData.photo
        // this.dialogImageUrl = file.url
        this.dialogVisible = true
      }
    },
    handleSuccess(response) {
      this.formData.photo = response.result
    },
    restModal() {
      this.dialogVisible = false
    },
    onExceed() {
      this.$xtoast.warning('头像仅可上传一张，请删除后重新上传！')
    },
    toggle(index) {
      if (index === 1) {
        this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password'
      } else {
        this.confirmFieldType = this.confirmFieldType === 'password' ? 'text' : 'password'
      }
    },
    check() {
      if (this.formData.password !== this.formData.confirmPassword) {
        this.errorFlag = true
      } else {
        this.errorFlag = false
      }
    },
    toggleMember(memberId) {
      this.memberId = memberId
      this.tabIndex = 1
    },
  },
}
</script>

<style lang="scss">
  .el-upload--picture-card {
    border: none !important;
    text-align: left;
    padding: 0px !important;
    margin: 0px !important;
    line-height: 30px !important;
  }
  .upload-cx:hover:after{
    position:absolute;
    display: block;
    width:100%;
    height: 100%;
    border-radius: 50%;
    // background-color: rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.5) url("../../../system/usercenter/utils/browse.png") no-repeat center center;
    background-size: 30px;
    content: attr(data-text);
    transition:all 3s ease;
    color: #FFF;
    text-align: center !important;
    vertical-align: middle !important;
    cursor: pointer;
  }
</style>
