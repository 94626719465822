<template>
  <div>
    <x-el-table
      title="成员列表"
      ref="table"
      :card="false"
      :options="options"
      @search="searchHandle"
      @rowAssets="rowAssets"
      @rowSelected="rowSelected"
      @rowUnbind="rowUnbind">
      <template slot="actions">
        <x-button name="绑定成员" icon="PlusIcon" @click="showModal"></x-button>
        <x-button name="解绑成员" icon="UserMinusIcon" variant="light-danger" class="ml-1" @click="unbind"></x-button>
      </template>
    </x-el-table>
    <b-modal
      ref="myModal"
      cancel-variant="flat-secondary"
      ok-title="绑定"
      cancel-title="取消"
      centered
      size="xl"
      body-class="x-scrollbar pl-4 pr-4"
      title="绑定成员"
      :visible="showFlag"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <x-el-table
        ref="bind_table"
        :card="false"
        :options="userOptions"
        @search="searchUserHandle"
        @rowSelected="rowUserSelected">
        <template slot="adminMember" slot-scope="{row}">
          <b-form-checkbox
            v-model="row.admin"
            switch
          />
        </template>
      </x-el-table>
    </b-modal>
  </div>
</template>
<script>
import XElTable from '@core/components/cx/table/XElTable.vue'
import XButton from '@/@core/components/cx/button/XButton.vue'
import { BModal, BFormCheckbox } from 'bootstrap-vue'
import { queryMemberList, unbindMember, queryUnbindMembers, bindMember } from '@/api/system/setup/tenant'

export default {
  components: {
    XElTable,
    XButton,
    BModal,
    BFormCheckbox,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
  },
  data() {
    return {
      binds: [],
      showFlag: false,
      selectRows: [],
      options: {
        rowSelectable: true,
        // 新增按钮
        addBtn: false,
        hideStyleSwitch: true,
        actions: [
          { action: 'assets', name: '查看资产', icon: 'AlignJustifyIcon', tip: '查看资产' },
          { action: 'unbind', name: '解绑', icon: 'UserXIcon', tip: '解绑' },
        ],
        actionFilter: action => action === 'assets' || action === 'unbind',
        columns: [{
          label: '名称',
          labelShow: true,
          prop: 'name',
          editDisable: true,
          searchShow: true,
        }, {
          label: '是否管理员',
          labelShow: true,
          prop: 'adminMember',
          type: 'select',
          addShow: false,
          searchShow: false,
          rowSelect: true,
          selectVariant: prop => {
            if (!prop) {
              return 'light-danger'
            }
            return 'light-success'
          },
          dictData: [{ text: '是', value: true }, { text: '否', value: false }],
          getDictLabel: option => option.label,
          props: { label: 'text', value: 'value' },
        }, {
          label: '状态',
          labelShow: true,
          prop: 'state',
          type: 'select',
          addShow: false,
          rowSelectDot: true,
          selectVariant: prop => {
            if (prop.value === 'stopped') {
              return 'text-danger'
            }
            return 'text-success'
          },
          dictData: [{ text: '正常', value: 'enabled' }, { text: '禁用', value: 'disabled' }],
          getDictLabel: option => option.label,
          props: { label: 'text', value: 'value' },
          searchShow: true,
        },
        ],
      },
      userOptions: {
        rowSelectable: true,
        // 新增按钮
        addBtn: false,
        hideStyleSwitch: true,
        actions: [],
        columns: [{
          label: '名称',
          labelShow: true,
          prop: 'name',
          editDisable: true,
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入证书名称',
          },
        }, {
          label: '状态',
          labelShow: true,
          prop: 'status',
          type: 'select',
          addShow: false,
          rowSelectDot: true,
          selectVariant: prop => {
            if (prop === 0) {
              return 'text-danger'
            }
            return 'text-success'
          },
          dictData: [{ label: '正常', value: 1 }, { label: '已禁用', value: 0 }],
          getDictLabel: option => option.label,
          searchShow: true,
        }, {
          label: '是否管理员',
          labelShow: true,
          prop: 'adminMember',
          type: 'select',
          addShow: false,
          searchShow: false,
          rowSelect: true,
          selectVariant: prop => {
            if (!prop) {
              return 'light-danger'
            }
            return 'light-success'
          },
          dictData: [{ text: '是', value: true }, { text: '否', value: false }],
          getDictLabel: option => option.label,
          props: { label: 'text', value: 'value' },
        },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      queryMemberList(page.pageIndex - 1, page.pageSize, params, this.id).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowSelected(rows) {
      this.selectRows = rows
    },
    rowUnbind(data, done) {
      unbindMember(this.detail.tenant.id, [data.id]).then(() => {
        done()
      })
    },
    unbind() {
      if (!this.selectRows || this.selectRows.length === 0) {
        this.$xtoast.warning('请至少选择一条数据！')
        return
      }
      unbindMember(this.detail.tenant.id, this.selectRows.map(item => item.id)).then(() => {
        this.$xtoast.success('解绑成功！')
        this.selectRows = []
        this.$refs.table.__searchHandle()
      })
    },
    handleOk(bvModalEvt) {
      if (!this.binds || this.binds.length === 0) {
        this.$xtoast.warning('请至少选择一条数据！')
        bvModalEvt.preventDefault()
        return
      }
      let params = []
      this.binds.forEach(user => {
        let obj = {
          name: user.name,
          userId: user.id,
          admin: user.admin,
        }
        params.push(obj)
      })
      bindMember(this.id, params).then(() => {
        this.$xtoast.success('绑定成功！')
        this.$refs.table.__searchHandle()
        this.showFlag = false
      })
    },
    resetModal() {
      this.showFlag = false
    },
    showModal() {
      this.showFlag = true
    },
    searchUserHandle(page, params, done) {
      queryUnbindMembers(this.id, page.pageIndex - 1, page.pageSize, params).then(resp => {
        resp.data.result.data.forEach(user => {
          user.admin = false
        })
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowUserSelected(rows) {
      this.binds = rows
    },
    rowAssets(data) {
      this.$emit('toggleMember', data.id)
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
</style>
