<template>
  <div>
      <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <v-select
            v-model="memberIdCopy"
            :options="childList"
            placeholder="请选择租户成员"
            label="name"
            :reduce="option => option.id"
            v-on:option:selected="option => selectMember(option)"
          >
            <template slot="no-options">{{$t('noData')}}</template>
          </v-select>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2 "
        >
          <div
            class="d-flex justify-content-center"
          >
            <b-badge
              variant="primary"
            >
              已发布产品
            </b-badge>
          </div>
          <div class="text-primary d-flex justify-content-center font-medium-2 x-link mt-sm-1 mb-sm-1">{{productPublishedCount}}</div>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <div
            class="d-flex justify-content-center"
          >
            <b-badge
              variant="warning"
            >
              未发布产品
            </b-badge>
          </div>
          <div
            class="text-warning d-flex justify-content-center font-medium-2 x-link mt-sm-1 mb-sm-1"
          >
            {{productUnpublishedCount}}
          </div>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <div
            class="d-flex justify-content-center"
          >
            <b-badge variant="success">
              在线设备
            </b-badge>
          </div>
          <div
            class="text-success d-flex justify-content-center font-medium-2 x-link mt-sm-1 mb-sm-1"
          >
            {{deviceOnlineCount}}
          </div>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <div
            class="d-flex justify-content-center"
          >
            <b-badge
              variant="danger"
            >
              离线设备
            </b-badge>
          </div>
          <div
            class="text-danger d-flex justify-content-center font-medium-2 x-link mt-sm-1 mb-sm-1"
          >
            {{deviceOfflineCount}}
          </div>
        </b-col>
      </b-row>
        <b-tabs content-class="mt-2">
          <b-tab
            active>
            <template #title>
              <div class="d-flex align-items-center">
                <feather-icon icon="BoxIcon" class="ml-1" />
                <span class="mr-1">产品</span>
              </div>
            </template>
            <product-list
              :userId="userId"
              :detail="detail"
              @freshCount="productCount"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <div class="d-flex align-items-center">
                <feather-icon icon="HardDriveIcon" class="ml-1" />
                <span class="mr-1">设备</span>
              </div>
            </template>
            <device-list
              :userId="userId"
              :detail="detail"
              @freshCount="productCount"
            />
          </b-tab>
        </b-tabs>
    </b-card-body>
  </div>
</template>
<script>
import { BRow, BCol, BBadge, BCardBody, BTabs, BTab } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { findTenantMembers, productCount, deviceCount } from '@/api/system/setup/tenant'
import productList from './productList.vue'
import deviceList from './deviceList.vue'

export default {
  components: {
    vSelect,
    BRow,
    BCol,
    BBadge,
    BCardBody,
    BTabs,
    BTab,
    productList,
    deviceList,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    memberId: {
      type: String,
      default: '',
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      memberIdCopy: undefined,
      // 成员用户ID
      userId: undefined,
      childList: [],
      productPublishedCount: 0,
      productUnpublishedCount: 0,
      deviceOnlineCount: 0,
      deviceOfflineCount: 0,
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        if (val) {
          // 查询租户成员
          this.findTenantMembers(val)
        }
      },
    },
    memberId: {
      immediate: true,
      handler() {
        this.memberIdCopy = this.memberId
        // 查询成员产品各状态数量
        // this.productCount()
        // 查询成员设备各状态数量
        // this.deviceCount()
      },
    },
    memberIdCopy: {
      immediate: true,
      handler() {
        this.childList.forEach(child => {
          if (child.id === this.memberIdCopy) {
            this.userId = child.userId
          }
        })
        // 查询成员产品各状态数量
        this.productCount()
        // 查询成员设备各状态数量
        this.deviceCount()
      },
    },
  },
  methods: {
    findTenantMembers(id) {
      findTenantMembers(id).then(resp => {
        this.childList = resp.data.result
      })
    },
    //  获取产品状态数量
    productCount() {
      let stateArr = [1, 0]
      stateArr.forEach(state => {
        let params = {
          tenantId: this.detail.tenant.id,
          assetType: 'product',
        }
        if (this.userId) {
          params.memberId = this.userId
        }
        productCount(JSON.stringify(params), state).then(resp => {
          if (state === 1) {
            this.productPublishedCount = resp.data.result
          } else if (state === 0) {
            this.productUnpublishedCount = resp.data.result
          }
        })
      })
    },
    //  获取设备状态数量
    deviceCount() {
      let stateArr = ['online', 'offline']
      stateArr.forEach(state => {
        let params = {
          tenantId: this.detail.tenant.id,
          assetType: 'device',
        }
        if (this.userId) {
          params.memberId = this.userId
        }
        deviceCount(JSON.stringify(params), state).then(resp => {
          if (state === 'online') {
            this.deviceOnlineCount = resp.data.result
          } else if (state === 'offline') {
            this.deviceOfflineCount = resp.data.result
          }
        })
      })
    },
    selectMember(option) {
      console.log('2222222')
      this.userId = option.userId
    },
  },
}
</script>

<style lang="scss">
</style>
