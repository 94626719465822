<template>
  <div>
    <x-el-table
      ref="table"
      :options="options"
      :card="false"
      :dataPreHandle="dataPreHandle"
      @search="searchHandle"
      @rowUnbind="rowUnbind"
      @rowSelected="rowSelected">
      <template slot="actions">
        <x-button v-b-modal.modal-bindproduct name="绑定产品" icon="PlusIcon"></x-button>
        <x-button name="解绑产品" icon="XIcon" variant="light-danger" class="ml-1" @click="unbind"></x-button>
      </template>
    </x-el-table>
    <b-modal
      id="modal-bindproduct"
      ref="myModal"
      cancel-variant="flat-secondary"
      ok-title="绑定"
      cancel-title="取消"
      centered
      size="xl"
      body-class="x-scrollbar pl-4 pr-4"
      title="绑定产品"
      @hidden="resetModal"
      @ok="handleOk"
      >
      <div class="card card-body x-no-card x-no-shadow mt-n1 mb-n2">
        <div>
          <span class="x-require-indicator font-medium-2 x-text-bold x-pr-4px">*</span>
          <span class="x-text-bold ">请选择所属成员：</span>
        </div>
        <v-select
          v-model="users"
          :options="children"
          multiple
          class="mt-50"
          placeholder="请选择所属成员"
          label="name"
          v-on:option:selected="selectUsers()"
        />
      </div>
      <x-el-table
        ref="bind_table"
        :card="false"
        :options="bindOptions"
        @search="searchProductHandle"
        @rowSelected="rowProductSelected">
      </x-el-table>
    </b-modal>
  </div>
</template>

<script>
import XElTable from '@/@core/components/cx/table/XElTable.vue'
import XButton from '@/@core/components/cx/button/XButton.vue'
import vSelect from 'vue-select'
import { queryTenantProducts, findTenantMembers, unbinds, binds } from '@/api/system/setup/tenant'
import {
  count,
} from '@/api/device-manage/product-list'

export default {
  components: {
    XElTable,
    vSelect,
    XButton,
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      // 是否是全部用户
      isAllUser: false,
      // 所属用户
      users: [],
      // 解绑ids
      selectIds: [],
      // 选择绑定ids
      bindIds: [],
      children: [],
      options: {
        hideStyleSwitch: true,
        addBtn: false,
        rowSelectable: true,
        actions: [
          { action: 'unbind', tip: '解绑', icon: 'XIcon' },
        ],
        columns: [{
          label: '图片',
          prop: 'photoUrl',
          type: 'image',
          labelShow: false,
          searchShow: false,
        }, {
          label: '产品ID',
          prop: 'id',
          editDisable: true,
          sortable: true,
        }, {
          label: '产品名称',
          prop: 'name',
          sortable: true,
          linkUrl: '/device-center/products/view/{id}',
        }, {
          label: '设备数量',
          prop: 'count',
          linkUrl: '/device-center/device-manager/devices?productId={id}',
          sortable: true,
          editShow: false,
          addShow: false,
          searchShow: false,
          rowCls: 'text-center',
        }, {
          label: '发布状态',
          prop: 'state',
          type: 'select',
          rowSelectDot: true,
          addShow: false,
          dictData: [{ label: '已发布', value: 1 }, { label: '未发布', value: 0 }],
          selectVariant: prop => {
            if (prop === 1) {
              return 'text-success'
            }
            return 'text-danger'
          },
          sortable: true,
          editShow: false,
        },
        ],
      },
      bindOptions: {
        contentHeight: '55vh',
        hideStyleSwitch: true,
        addBtn: false,
        rowSelectable: true,
        actions: [],
        columns: [{
          label: '图片',
          prop: 'photoUrl',
          type: 'image',
          labelShow: false,
          searchShow: false,
        }, {
          label: '产品ID',
          prop: 'id',
          editDisable: true,
          sortable: true,
        }, {
          label: '产品名称',
          linkUrl: '/device-center/products/view/{id}',
          prop: 'name',
          sortable: true,
        }, {
          label: '发布状态',
          prop: 'state',
          type: 'select',
          rowSelectDot: true,
          addShow: false,
          dictData: [{ label: '已发布', value: 1 }, { label: '未发布', value: 0 }],
          selectVariant: prop => {
            if (prop === 1) {
              return 'text-success'
            }
            return 'text-danger'
          },
          sortable: true,
          editShow: false,
        },
        ],
      },
    }
  },
  watch: {
    userId: {
      immediate: true,
      handler() {
        if (this.$refs.table) {
          this.$refs.table.__searchHandle()
        }
      },
    },
  },
  created() {
    findTenantMembers(this.detail.tenant.id).then(resp => {
      let childArr = [{ id: 'all', name: '全部成员' }]
      resp.data.result.forEach(item => {
        childArr.push(item)
      })
      this.children = childArr
    })
  },
  methods: {
    dataPreHandle(data, done) {
      count(data.id).then(resp => {
        data.count = resp.data.result
        done(data)
      })
    },
    searchHandle(page, params, done) {
      let tenant = {
        tenantId: this.detail.tenant.id,
        assetType: 'product',
      }
      if (this.userId) {
        tenant.memberId = this.userId
      }
      queryTenantProducts(tenant, page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowSelected(rows) {
      this.selectIds = []
      rows.forEach(item => {
        this.selectIds.push(item.id)
      })
    },
    rowUnbind(data, done) {
      let params = []
      params.push({ assetIdList: [data.id], assetType: 'product' })
      unbinds(this.detail.tenant.id, params).then(() => {
        this.selectIds = []
        done()
      })
    },
    /* 解除绑定 */
    unbind() {
      if (this.selectIds.length === 0) {
        this.$xtoast.warning('请至少选择一条数据！')
        return
      }
      let params = []
      params.push({ assetIdList: this.selectIds, assetType: 'product' })
      unbinds(this.detail.tenant.id, params).then(() => {
        this.$xtoast.success('解绑成功！')
        this.selectIds = []
        this.$refs.table.__searchHandle()
      })
    },
    searchProductHandle(page, params, done) {
      let tenant = {
        tenantId: this.detail.tenant.id,
        assetType: 'product',
        not: true,
      }
      if (this.userId) {
        tenant.memberId = this.userId
      }
      queryTenantProducts(tenant, page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowProductSelected(rows) {
      this.bindIds = []
      rows.forEach(item => {
        this.bindIds.push(item.id)
      })
    },
    // 绑定设备modal关闭
    resetModal() {
      this.userId = undefined
      this.bindIds = []
      this.$emit('freshCount')
    },
    // 绑定设备
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.bindIds.length === 0) {
        this.$xtoast.warning('请选择绑定产品！')
        return
      }
      if (this.users.length === 0) {
        this.$xtoast.warning('请选择所属用户！')
        return
      }
      let params = []
      if (!this.isAllUser) {
        this.users.forEach(user => {
          if (user.id !== 'all') {
            let param = {
              allPermission: true,
              assetIdList: this.bindIds,
              assetType: 'product',
              userId: user.userId,
            }
            params.push(param)
          }
        })
      } else {
        this.children.forEach(user => {
          if (user.id !== 'all') {
            let param = {
              allPermission: true,
              assetIdList: this.bindIds,
              assetType: 'product',
              userId: user.userId,
            }
            params.push(param)
          }
        })
      }
      binds(this.detail.tenant.id, params).then(() => {
        this.$xtoast.success('绑定成功！')
        this.userId = undefined
        this.bindIds = []
        this.$refs.myModal.hide()
        this.$refs.table.__searchHandle()
      })
    },
    //  选择用户
    selectUsers() {
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].id === 'all') {
          this.isAllUser = true
          break
        } else {
          this.isAllUser = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
