<template>
  <x-form-validator ref="refFormObserver">
    <x-form-feild label="头像">
      <div style="position: relative;width: 100px;height: 100px"  @click="handlePictureCardPreview">
        <b-avatar
          :class="formData.photo ? 'el-upload-list__item-thumbnail upload-cx' : 'el-upload-list__item-thumbnail'"
          style="width: 100px;height: 100px;"
          size="lg"
          :src="formData.photo" alt=""
          :variant="formData.photo ? 'light-primary' : 'light-secondary'"
        >
        </b-avatar>
      </div>
      <el-upload
        :action="action"
        :headers="headers"
        :multiple="false"
        :file-list="fileList"
        accept=".jpg, .png, .gif"
        :show-file-list="false"
        :on-exceed="onExceed"
        :on-success="handleSuccess">
        <b-button  variant="primary" size="sm" v-ripple.400="$x.ripple.primary" class="mt-1 ml-2" style="margin-left: 10px">上传</b-button>
      </el-upload>
      <b-modal
        title-class="x-text-bold"
        body-class="pl-4 pr-4"
        cancel-variant="flat-secondary"
        size="lg"
        ok-title="删除"
        cancel-title="取消"
        centered
        title="预览"
        :visible.sync="dialogVisible"
        @hidden="restModal"
        @ok="handleRemove">
        <img width="100%" :src="dialogImageUrl" alt="">
      </b-modal>
    </x-form-feild>
    <x-form-feild label="名称" require>
      <b-form-input
        v-model="formData.name"
        placeholder="请输入名称"
      />
    </x-form-feild>
    <x-form-feild label="用户名" require>
      <b-form-input
        v-model="formData.username"
        placeholder="请输入名称"
        class="x-clear-input"
      />
    </x-form-feild>
    <x-form-feild label="新密码" require>
      <b-input-group
        class="input-group-merge"
      >
        <b-form-input
          v-model="formData.password"
          class="form-control-merge x-clear-input"
          :type="newPasswordFieldType"
          name="register-password"
          placeholder="请输入新密码"
        />
        <b-input-group-append is-text>
          <feather-icon
            :icon="newPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'"
            class="cursor-pointer"
            @click="toggle(1)"
          />
        </b-input-group-append>
      </b-input-group>
    </x-form-feild>
    <x-form-feild label="确认密码" require>
      <b-input-group
        class="input-group-merge"
      >
        <b-form-input
          v-model="formData.confirmPassword"
          class="form-control-merge"
          size="lg"
          :type="confirmFieldType"
          name="register-password"
          placeholder="请输入确认密码"
          @keyup="check"
        />
        <b-input-group-append is-text>
          <feather-icon
            :icon="confirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'"
            class="cursor-pointer"
            @click="toggle(2)"
          />
        </b-input-group-append>
      </b-input-group>
      <small class="text-danger" v-if="errorFlag">与输入密码不一致</small>
    </x-form-feild>
    <x-form-feild label="说明">
      <b-form-textarea
        v-model="formData.description"
        placeholder="请输入描述"
      />
    </x-form-feild>
    <b-button class="ml-1" variant="primary" v-ripple.400="$x.ripple.primary" @click="enter">保存</b-button>
  </x-form-validator>
</template>
<script>
import { BFormInput, BFormTextarea, BButton, BModal, BAvatar, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import { add } from '@/api/system/setup/tenant'
import { getToken } from '@/@core/auth/token'

export default {
  components: {
    XFormValidator,
    XFormFeild,
    BFormInput,
    BFormTextarea,
    BButton,
    BModal,
    BAvatar,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      formData: {
        photo: undefined,
        name: undefined,
        password: undefined,
        confirmPassword: undefined,
        username: undefined,
        description: undefined,
      },
      errorFlag: false,
      confirmFieldType: 'password',
      oldPasswordFieldType: 'password',
      newPasswordFieldType: 'password',
      // 上传请求地址
      action: this.$xapi.UPLOAD,
      // 上传请求头部信息
      headers: {
        'X-Access-Token': getToken(),
      },
      dialogVisible: false,
      dialogImageUrl: '',
      fileList: [],
    }
  },
  watch: {
  },
  methods: {
    // 上传图片
    uploadSuccess(response) {
      this.$xtoast.success('上传成功')
      if (response.code === 'success') {
        this.formData.photo = response.result
      }
    },
    enter() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          add(this.formData).then(() => {
            this.$xtoast.success('新增成功！')
            this.formData = {
              photo: undefined,
              name: undefined,
              password: undefined,
              confirmPassword: false,
              username: undefined,
              description: undefined,
            }
            this.$emit('editSuccess')
          })
        }
      })
    },
    handleRemove() {
      this.fileList = []
      this.formData.photo = ''
    },
    handlePictureCardPreview() {
      if (this.formData.photo) {
        this.dialogImageUrl = this.formData.photo
        // this.dialogImageUrl = file.url
        this.dialogVisible = true
      }
    },
    handleSuccess(response) {
      this.formData.photo = response.result
    },
    restModal() {
      this.dialogVisible = false
    },
    onExceed() {
      this.$xtoast.warning('头像仅可上传一张，请删除后重新上传！')
    },
    toggle(index) {
      if (index === 1) {
        this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password'
      } else {
        this.confirmFieldType = this.confirmFieldType === 'password' ? 'text' : 'password'
      }
    },
    check() {
      if (this.formData.password !== this.formData.confirmPassword) {
        this.errorFlag = true
      } else {
        this.errorFlag = false
      }
    },
  },
}
</script>

<style lang="scss">
.el-upload--picture-card {
  border: none !important;
  text-align: left;
  padding: 0px !important;
  margin: 0px !important;
  line-height: 30px !important;
}
.upload-cx:hover:after{
  position:absolute;
  display: block;
  width:100%;
  height: 100%;
  border-radius: 50%;
  // background-color: rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.5) url("../../../system/usercenter/utils/browse.png") no-repeat center center;
  background-size: 30px;
  content: attr(data-text);
  transition:all 3s ease;
  color: #FFF;
  text-align: center !important;
  vertical-align: middle !important;
  cursor: pointer;
}
</style>
